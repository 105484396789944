<template>
  <div>
    <a-table
      @change="changePage"
      :pagination="pagination"
      :loading="loading"
      :columns="columns"
      :data-source="list"
      :rowKey="(record, index) => index"
    >
    
     <div
        class="promo-image"
        slot="userAvatar"
        slot-scope="row"
      >
        <img :src="row" />
      </div>
      <div
        class="promo-image"
        slot="promoImageCode"
        slot-scope="row"
      >
        <img :src="row" />
      </div>
    </a-table>
  </div>
</template>

<script>
export default {
  props: ["pagination", "loading", "list"],
  data() {
    return {
      columns: [
        {
          title: "用户ID",
          dataIndex: "userId",
          align: "center",
        },
        {
          title: "用户名",
          dataIndex: "userName",
          align: "center",
        },
        {
          title: "头像",
          dataIndex: "userAvatar",
          align: "center",
          scopedSlots: { customRender: "userAvatar" },
        },
        
        {
          title: "推广码",
          dataIndex: "promoCode",
          align: "center",
        },
        {
          title: "推广二维码",
          dataIndex: "promoImageCode",
          align: "center",
          scopedSlots: { customRender: "promoImageCode" },
        },
        {
          align: "center",
          key: "time",
          customRender: (text, row, index) => {
            return <span style="color: #32CD32;">{row.time}</span>;
          },
        },
      ],
    };
  },
  methods: {
    changePage(page) {
      this.$emit("changePage", page);
    },
  },
};
</script>

<style lang="scss">
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  height: 100px;
}
.promo-image {
  img {
    height: 80px;
  }
}
</style>