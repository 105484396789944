<template>
  <div>
    <a-modal
      v-model="show"
      title="新增"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancelForm"
      @ok="submit"
    >
      <a-form-model
        ref="ruleForm"
        :model="forms"
        v-bind="layout"
      >
        <a-form-model-item label="用户ID">
          <a-input
            size="small"
            v-model="forms.userId"
          ></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      forms: {},
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
    };
  },
  methods: {
    async submit() {
      this.$loading.show();
      const res = await this.axios.get("/dq_admin/silverVip/setExtension", {
        params: this.forms,
      });
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message || "添加成功");
      this.show = false;
      this.$emit("success");
      this.$emit("close");
    },
    cancelForm() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>