<template>
  <div class="page">
    <div class="page-header">
      <div>
        <!--  -->
        <a-input
          allowClear
          v-model="params.userId"
          size="small"
          style="width: 120px; margin-right: 10px"
          placeholder="用户ID"
        ></a-input>
        <a-button
          @click="search"
          size="small"
          style="margin-left: 10px"
        >搜索</a-button>
      </div>

      <div>
        <a-button
          type="primary"
          size="small"
          @click="add"
        >新增</a-button>
      </div>
    </div>
    <div class="page-body">
      <PageTable
        :list="list"
        :loading="loading"
        :pagination="pagination"
        @changePage="changePage"
      ></PageTable>
    </div>
    <AddForm
      ref="add"
      v-if="addShow"
      @close="addShow = false"
      @success="getList"
    ></AddForm>
  </div>
</template>

<script>
import PageTable from "@/views/cmsPage/dollarManager/agentmanage/PageTable.vue";
import AddForm from "@/views/cmsPage/dollarManager/agentmanage/add.vue";
export default {
  data() {
    return {
      addShow: false,
      list: [],
      params: {
        pageNum: 1,
        pageSize: 20,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      loading: false,
    };
  },
  components: {
    PageTable,
    AddForm,
  },

  created() {
    this.getList();
  },
  methods: {
    add() {
      this.addShow = true;
    },
    search(){
      this.params.pageNum = 1;
      this.pagination.current = 1;
      this.getList();
    },
    // 切换分页
    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList();
    },

    async getList() {
      this.loading = true;
      const res = await this.axios(
        "/dq_admin/silverVip/exensionList",
        {
          params: this.params,
        }
      );
      this.loading = false;
      if (res.status != 200) return (this.list = []);
      this.list = res.data.records;
      this.pagination.total = res.data.total;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
}
.page-header{
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.page-body {
  flex: 1;
  overflow: auto;
}
</style>