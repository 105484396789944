var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          pagination: _vm.pagination,
          loading: _vm.loading,
          columns: _vm.columns,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "userAvatar",
            fn: function(row) {
              return _c("div", { staticClass: "promo-image" }, [
                _c("img", { attrs: { src: row } })
              ])
            }
          },
          {
            key: "promoImageCode",
            fn: function(row) {
              return _c("div", { staticClass: "promo-image" }, [
                _c("img", { attrs: { src: row } })
              ])
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }