var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          [
            _c("a-input", {
              staticStyle: { width: "120px", "margin-right": "10px" },
              attrs: { allowClear: "", size: "small", placeholder: "用户ID" },
              model: {
                value: _vm.params.userId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "userId", $$v)
                },
                expression: "params.userId"
              }
            }),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "small" },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "a-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.add }
              },
              [_vm._v("新增")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "page-body" },
        [
          _c("PageTable", {
            attrs: {
              list: _vm.list,
              loading: _vm.loading,
              pagination: _vm.pagination
            },
            on: { changePage: _vm.changePage }
          })
        ],
        1
      ),
      _vm.addShow
        ? _c("AddForm", {
            ref: "add",
            on: {
              close: function($event) {
                _vm.addShow = false
              },
              success: _vm.getList
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }